<template>
  <div class="main">
    <!-- 顶部 -->
    <div class="main-top">
      <img class="top-bar" src="@/assets/images/mobile/bg1.png" />
      <div class="main-top-title">最高可领取XX元现金红包</div>
    </div>
    <!-- 主体 -->
    <div class="main-content">
      <div class="reward-box">
        <div class="reward-box-tips">
          <img src="@/assets/images/mobile/bg2.png" />
          <span>点亮碎片领奖励</span>
        </div>

        <div
          class="reward-img-box"
          :style="{
            backgroundImage: 'url(' + imgSrc + ')'
          }"
        >
          <img
            :src="
              coordinateList.includes(item.coordinate) ? item.newUrl : item.url
            "
            :class="`img-abs img-abs${item.id}`"
            v-for="item in list"
            :key="item.id"
          />
        </div>

        <div class="progress-box">
          <div class="progress-left">
            <img class="icon" src="@/assets/images/xbzq/right-icon1.png" />
            <div class="progress-box-chind">
              <el-progress
                :percentage="percentage1"
                color="#FF8A01"
                :text-inside="true"
                :format="format1"
              ></el-progress>
            </div>
          </div>
          <div class="progress-right">
            <img class="icon" src="@/assets/images/xbzq/right-icon2.png" />
            <div class="progress-box-chind">
              <el-progress
                :percentage="percentage2"
                :stroke-width="15"
                color="#FF8A01"
                :text-inside="true"
                :format="format2"
              ></el-progress>
            </div>
          </div>
        </div>

        <div class="fragment-box">
          <div class="lubo-box-fa">
            <div class="lubo-box">
              <div class="lubo" id="lubo">
                <div class="lubo-item" v-for="item in list" :key="item.id">
                  <div class="lubo-item-top-box">
                    <div class="lubo-item-fragment">
                      <img
                        class="fragment2-img"
                        :src="
                          `https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment2/${
                            coordinateList.includes(item.coordinate)
                              ? item.fragment
                              : item.fragment + '-0'
                          }.png`
                        "
                      />
                      <img
                        v-if="coordinateList.includes(item.coordinate)"
                        class="triangle-dui"
                        src="@/assets/images/triangle-dui.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- 上一个 -->
              <div class="prev-box lubo-btn" @click="prev">
                <img src="@/assets/images/triangle-left.png" />
              </div>
              <!-- 下一个 -->
              <div class="next-box lubo-btn" @click="next">
                <img src="@/assets/images/triangle-right.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="btns">
          <div
            class="btns-box"
            :style="{
              backgroundImage: 'url(' + imgSrc3 + ')'
            }"
            @click="gourl"
          >
            去点亮
          </div>
          <div
            :class="[
              'btns-box',
              { 'btns-box2': percentage1 !== 100 || islingqu }
            ]"
            :style="{
              backgroundImage: 'url(' + imgSrc3 + ')'
            }"
            @click="getRewards"
          >
            {{ islingqu ? "已领取" : "领取奖励" }}
          </div>
        </div>
      </div>
      <!-- 点亮记录 -->
      <div class="winning-list">
        <div class="winning-list-top">
          <img
            class="img-lefts"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
          <div>点亮记录</div>
          <img
            class="img-right"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
        </div>
        <div class="list-box" @scroll="scroll">
          <div
            class="list-item2"
            v-for="(item, index) in jigsawPieceRecordsList"
            :key="index"
          >
            <div class="list-item-left">{{ item.CreateDate | fromDate }}</div>
            <div class="list-item-content">
              {{ item.ProgressName }}
            </div>
            <div class="list-item-name">
              {{ item.PieceName }}
            </div>
          </div>
          <div
            class="Light-award-box-tips"
            v-if="jigsawPieceRecordsList.length === 0"
          >
            暂无点亮记录
          </div>
        </div>
      </div>

      <!-- 我的奖品 -->
      <div class="winning-list">
        <div class="winning-list-top">
          <img
            class="img-lefts"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
          <div>我的奖品</div>
          <img
            class="img-right"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
        </div>
        <div class="list-box">
          <div
            class="list-item2"
            v-for="(item, index) in newLevelList"
            :key="index"
          >
            <div class="Light-award-left">{{ item.Name }}</div>
            <div class="list-right list-AwardStatus">
              {{ item.AwardStatus === 2 ? "已领取" : "未领取" }}
            </div>
          </div>
          <div
            class="Light-award-box-tips"
            v-if="jigsawPieceRecordsList.length === 0"
          >
            暂无我的奖品记录
          </div>
        </div>
      </div>

      <!-- 我的奖品 -->
      <div class="winning-list winning-list2">
        <div class="winning-list-top">
          <img
            class="img-lefts"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
          <div>规则说明</div>
          <img
            class="img-right"
            src="@/assets/images/mobile/turntable/bg4.png"
          />
        </div>
        <!-- 规则 -->
        <div class="text text1"><span></span>游戏玩法</div>
        <div class="text text2">
          参与新版速调吧答卷完成的同时可以随机获得一片碎片，集齐每一关卡的碎片，可以领取每一关卡的奖励。奖励包含，抽奖卡、翻倍卡、免审卡、复活卡等等
        </div>
        <div class="text text3"><span></span>游戏规则</div>
        <div class="text c">
          只针对商业调查才会获得集卡碎片，小幸运调查，K调查，G调查都不会获得。
          每完成一个商业调查都会随机获得，本次总共推出6个关卡，每个关卡的难度也会逐渐增加，获得奖励也会更加丰富。
        </div>
      </div>
    </div>

    <div class="zhezhao" v-if="fragmentShow"></div>
    <div class="jl-box" v-if="fragmentShow">
      <div class="jlt-box">
        <img class="jltc" src="@/assets/images/jiangli.png" />
        <div class="jltc-name">
          {{
            this.type === this.allLevelList.length
              ? "恭喜你完成全部关卡"
              : "恭喜解锁下一关"
          }}
        </div>
        <div
          :class="[
            'jltc-card',
            {
              'jltc-card2': isIoconNumber === 2
            },
            {
              'jltc-card3': isIoconNumber === 3
            },
            {
              'jltc-card4': isIoconNumber === 4
            },
            {
              'jltc-card5': isIoconNumber === 5
            }
          ]"
        >
          <div class="card-item" v-if="Points">
            <img class="card-icon card-icon1" src="@/assets/images/icon1.png" />
            <div class="card-item-name">金币X{{ Points }}</div>
          </div>
          <div class="card-item" v-if="LotteryCard">
            <img class="card-icon" src="@/assets/images/icon2.png" />
            <div class="card-item-name">抽奖卡X{{ LotteryCard }}</div>
          </div>
          <div class="card-item" v-if="DoubleCard">
            <img class="card-icon" src="@/assets/images/icon3.png" />
            <div class="card-item-name">免审卡X{{ DoubleCard }}</div>
          </div>
          <div class="card-item" v-if="UncheckCard">
            <img class="card-icon" src="@/assets/images/icon4.png" />
            <div class="card-item-name">翻倍卡X{{ UncheckCard }}</div>
          </div>
          <div class="card-item" v-if="ResurgentCard">
            <img class="card-icon" src="@/assets/images/icon5.png" />
            <div class="card-item-name">复活卡X{{ ResurgentCard }}</div>
          </div>
        </div>
        <div class="wenben" @click="getAccept">开心收下</div>
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="fragmentShow = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { list1, list2, list3, list4, list5, list6 } from "@/utlis/listData.js";
export default {
  filters: {
    fromDate(data) {
      return data.slice(0, 16);
    }
  },
  data() {
    return {
      imgSrc: `https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/photo1.png`,
      imgSrc2: require("@/assets/images/xbzq/right-top.png"),
      imgSrc3: require("@/assets/images/xbzq/btn-bg.png"),
      type: null,
      list: [],
      percentage1: 0,
      percentage2: 0,
      allLevelList: [],
      newLevelList: [],
      coordinateList: [],
      luboIndex: 0,
      lightType: 1,
      page1: 1,
      jigsawPieceRecordsList: [],
      flag: false,
      fragmentShow: false,
      islingqu: false,
      Points: 0, //金币
      LotteryCard: 0, //抽奖
      DoubleCard: 0, //翻倍
      UncheckCard: 0, //免审
      ResurgentCard: 0, //复活
      isbtn: false
    };
  },
  async created() {
    await this.jigsawLevels();
    await this.memberJigsawLevels();
    await this.getJigsawPieceRecords();
    this.countfun();
  },
  computed: {
    isIoconNumber() {
      let number = 0;

      if (this.Points) {
        number++;
      }
      if (this.LotteryCard) {
        number++;
      }
      if (this.DoubleCard) {
        number++;
      }
      if (this.UncheckCard) {
        number++;
      }
      if (this.ResurgentCard) {
        number++;
      }

      return number;
    }
  },
  methods: {
    format1() {
      let number = null;
      if (this.coordinateList) {
        number = this.coordinateList.length + "/" + (this.type + 3);
      }
      return number;
    },
    format2() {
      let number = null;
      if (this.allLevelList.length !== 0) {
        number = this.type + "/" + this.allLevelList.length;
      }
      return number;
    },
    gourl() {
      this.$router.push("/tasks");
    },
    //全部关卡
    async jigsawLevels() {
      const data = await this.$http.get("/api/v1/jigsawLevels");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        this.allLevelList = response.response;
      }
    },
    //完成关卡
    async memberJigsawLevels() {
      const data = await this.$http.get("/api/v1/memberJigsawLevels");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        this.newLevelList = response.response;
        console.log("this.newLevelList", this.newLevelList);
      }
    },
    //计算相关信息
    async countfun() {
      //判断是第几关

      // 判断是否有奖励没领取
      const awardStatusIndex = this.newLevelList.findIndex(item => {
        return item.AwardStatus === null || item.AwardStatus === 1;
      });

      if (awardStatusIndex !== -1) {
        this.type = awardStatusIndex + 1;
      } else if (this.allLevelList.length === this.newLevelList.length) {
        this.type = this.newLevelList.length;
      } else {
        this.type = this.newLevelList.length + 1;
      }
      // this.type = 6;
      this.chagenIslingqu();
      this.imgSrc = `https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/photo${this.type}.png`;
      console.log("this.type", this.type);

      //获取关卡碎片
      switch (this.type) {
        case 1:
          this.list = list1;
          break;
        case 2:
          this.list = list2;
          break;
        case 3:
          this.list = list3;
          break;
        case 4:
          this.list = list4;
          break;
        case 5:
          this.list = list5;
          break;
        case 6:
          this.list = list6;
          break;
      }

      //计算关卡进度
      this.percentage2 = parseInt((this.type * 100) / this.allLevelList.length);

      //获取当前关卡碎片
      let newList = [];
      const data = await this.$http.get(
        `/api/v1/memberJigsawPieces?progressID=${this.type}`
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        response.response.forEach(item => {
          //过滤掉非当前关卡的碎片
          if (item.ProgressID === this.type) {
            newList.push(item.Coordinate);
          }
        });
      }
      this.coordinateList = Array.from(new Set(newList));
      console.log("111", this.coordinateList, this.allLevelList);
      //获取当前关卡所需全部碎片
      let allCoordinateNumber = this.type + 3;

      //计算碎片进度
      this.percentage1 = parseInt(
        (this.coordinateList.length * 100) / allCoordinateNumber
      );
      if (this.percentage1 > 100) {
        this.percentage1 = 100;
      }
      if (this.percentage2 > 100) {
        this.percentage2 = 100;
      }
      console.log(
        "111",
        " this.percentage1",
        this.percentage1,
        this.percentage2
      );
    },
    //判断奖励是否领取
    chagenIslingqu() {
      if (this.newLevelList[this.type - 1]) {
        if (this.newLevelList[this.type - 1].AwardStatus === 2) {
          this.islingqu = true;
        } else {
          this.islingqu = false;
        }
      }
    },
    //切换上一个
    prev() {
      if (this.luboIndex === 0) {
        this.$message.warning("已经到最左边了");
        return;
      }
      this.luboIndex--;
      const right = this.luboIndex * 18;
      const dom = document.getElementById("lubo");
      dom.style.transform = `translateX(-${right}vw)`;
    },
    //切换下一个
    next() {
      if (this.luboIndex === this.type - 1) {
        this.$message.warning("已经到最右边了");
        return;
      }
      this.luboIndex++;
      const right = this.luboIndex * 18;
      const dom = document.getElementById("lubo");
      dom.style.transform = `translateX(-${right}vw)`;
    },
    changeLightType(type) {
      this.lightType = type;
      if (type === 1) {
        this.page1 = 1;
        this.jigsawPieceRecordsList = [];
        this.getJigsawPieceRecords();
      } else {
        this.page1 = 1;
      }
    },
    //点亮记录历史
    async getJigsawPieceRecords() {
      this.flag = false;
      const data = await this.$http.get(
        `/api/v1/jigsawPieceRecords?page=${this.page1}&size=10`
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        console.log("this.jigsawPieceRecords", response.response.data);

        if (response.response.data.length !== 0) {
          this.flag = true;
          this.page1++;
          this.jigsawPieceRecordsList = this.jigsawPieceRecordsList.concat(
            response.response.data
          );
        } else {
          this.$message.warning("没有更多数据");
        }
      }
    },
    scroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollHeight - (scrollTop + clientHeight) <= 0) {
        if (this.flag) {
          this.getJigsawPieceRecords();
        }
      }
    },
    async getRewards() {
      if (this.isbtn) {
        return;
      }
      if (this.percentage1 === 100 && !this.islingqu) {
        console.log(this.newLevelList);
        this.isbtn = true;
        const data = this.newLevelList[this.type - 1];
        this.Points = data.Points;
        this.LotteryCard = data.LotteryCard;
        this.DoubleCard = data.DoubleCard;
        this.UncheckCard = data.UncheckCard;
        this.ResurgentCard = data.ResurgentCard;

        let id = this.newLevelList[this.type - 1].ID;
        const data2 = await this.$http.post(`/api/v1/pickupReward`, null, {
          params: {
            progressID: id
          }
        });

        let response = JSON.parse(data2.request.response);
        if (response.status === 200 && response.response) {
          this.fragmentShow = true;
          await this.memberJigsawLevels();
          this.countfun();
          this.isbtn = false;
        }
      }
    },
    //收下奖励
    async getAccept() {
      this.fragmentShow = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: #ff563f;
  padding-bottom: 50px;

  .main-top {
    width: 100%;
    position: relative;

    .top-bar {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .main-top-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-10%);
      padding: 0 5vw;
      height: 6vw;
      line-height: 6vw;
      border-radius: 3vw;
      text-align: center;
      color: #ff2c2c;
      background: #ffeac3;
      box-shadow: inset 0.5vw 1vw 6vw 0px rgba(255, 255, 255, 0.6);
      z-index: 2;
      font-size: 3vw;
    }
  }

  .main-content {
    width: 100%;
    padding: 0 11px;
    box-sizing: border-box;

    .reward-box {
      width: 100%;
      box-sizing: border-box;
      border: 4px solid #ffba53;
      border-radius: 12px;
      background: #fff0db;

      .reward-box-tips {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 32.5vw);
        margin-top: 2.5vw;
        height: 11.5vw;

        img {
          width: 100%;
          height: 100%;
        }

        span {
          position: absolute;
          display: inline-block;
          color: #fff;
          font-size: 4vw;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          text-align: center;
          z-index: 2;
          left: 0;
          font-weight: bolder;
          letter-spacing: 0.5vw;
        }
      }
    }

    .reward-img-box {
      width: calc(100vw - 44px);
      height: calc(100vw - 44px);
      background-size: 100% 100%;
      background-position: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 2.5vw;
      box-sizing: border-box;
      border: 1px solid #fff;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

.progress-box {
  display: flex;
  width: 100%;
  margin-top: 9.25vw;
  justify-content: space-between;
  padding: 0 3.25vw;
  box-sizing: border-box;

  .progress-left {
    display: flex;
    align-items: center;
    width: 45%;

    .icon {
      width: 7vw;
      height: 7vw;
      flex-shrink: 0;
    }

    .progress-box-chind {
      width: 100%;
      height: 7.5vw;
      background: linear-gradient(163deg, #ffac42 0%, #ffc45b 100%);
      border-radius: 3.75vw;
      padding: 1.75vw;
      box-sizing: border-box;
      margin-left: 3.25vw;
      position: relative;

      .el-progress {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .progress-right {
    display: flex;
    width: 45%;
    align-items: center;

    .icon {
      width: 7vw;
      height: 7vw;
      flex-shrink: 0;
    }

    .progress-box-chind {
      width: 100%;
      height: 7.5vw;
      background: linear-gradient(163deg, #ffac42 0%, #ffc45b 100%);
      border-radius: 3.75vw;
      padding: 1.75vw;
      box-sizing: border-box;
      margin-left: 3.25vw;
      position: relative;

      .el-progress {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 7.5vw;
  margin-bottom: 3.5vw;
  width: 100%;
  padding: 0 3.25vw;
  box-sizing: border-box;

  .btns-box {
    cursor: pointer;
    width: 35.5vw;
    height: 12.5vw;
    background-size: 100% 100%;
    background-position: center;
    font-size: 4vw;
    color: #fff;
    display: flex;
    line-height: 11vw;
    justify-content: center;

    // align-items: center;
    img {
      width: 6.5vw;
      height: 6.5vw;
      margin-right: 3.5vw;
      margin-top: 1.5vw;
    }
  }

  .btns-box2 {
    cursor: no-drop;
    opacity: 0.5;
  }
}

.text {
  font-size: 3.5vw;
  color: #ff4024;
  text-align: left;
  width: 100%;
  padding: 0 22px 0 15px;
  box-sizing: border-box;

  span {
    display: inline-block;
    width: 2vw;
    height: 2vw;
    transform: rotate(45deg);
    background-color: #ff4024;
    margin-right: 1.5vw;
  }
}

.text1 {
  margin-top: 1.75vw;
}

.text2 {
  margin-top: 1.75vw;
}

.text3 {
  margin-top: 2.5vw;
}

// 碎片
.fragment-box {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 44px);
  margin-top: 9vw;

  .lubo-box-fa {
    width: 100%;
    height: 21.75vw;
    background-color: #fde0b6;
    border-radius: 2.5vw;
    padding: 1.25vw;
    position: relative;

    // top: -32px;
    .lubo-box {
      width: 100%;
      height: 100%;
      background-color: #fde0b6;
      border-radius: 3vw;
      overflow: hidden;
      position: relative;

      .lubo {
        display: table;
        width: auto;
        height: 100%;
        transition: transform ease 0.3s;
        display: flex;
        padding: 0 5.75vw;

        .lubo-item {
          display: table-cell;
          flex-shrink: 0;
          margin-right: 4.5vw;
          width: 13.5vw;

          .lubo-item-top-box {
            width: 13.5vw;
            height: 13.5vw;
            background: #ffb330;
            border-radius: 1.5vw;
            margin-top: 2.75vw;
            display: flex;
            justify-content: center;
            align-items: center;

            .lubo-item-fragment {
              width: 10.75vw;
              height: 10.75vw;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .fragment2-img {
                max-width: 100%;
                max-height: 100%;
              }

              .triangle-dui {
                width: 3.5vw;
                height: 3.5vw;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
              }
            }
          }

          .lubo-item-bottom-box {
            width: 104px;
            height: 34px;
            background: #ffb330;
            border-radius: 17px;
            margin-top: 20px;
            margin-left: 18px;
            text-align: center;
            line-height: 34px;
            color: #fff;
          }

          .lubo-item-bottom-box-active {
            background: linear-gradient(357deg, #ff6332 0%, #ff8500 100%);
          }
        }
      }

      .lubo-btn {
        position: absolute;
        width: 4.5vw;
        height: 100%;
        background: #fde0b6;
        top: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 3vw;
          height: 3vw;
        }
      }

      .prev-box {
        left: 0;
      }

      .next-box {
        right: 0;
      }
    }
  }
}

.winning-list {
  width: 100%;
  height: 60vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, #ffe4c6 0%, #ffe4c6 100%);
  border-radius: 2vw;
  border: 4px solid #fff;
  margin-top: 4vw;

  .winning-list-top {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.5vw;
    color: #ff3434;
    font-weight: bolder;
    letter-spacing: 0.5vw;
    margin-top: 2vw;

    img {
      width: 10vw;
      height: 1vw;
    }

    .img-lefts {
      transform: rotate(180deg);
      margin-right: 2vw;
    }

    .img-right {
      margin-left: 2vw;
    }
  }

  .list-box {
    height: 45vw;
    overflow: auto;
    margin-top: 3vw;

    .list-item {
      display: flex;
      justify-content: center;
      color: #ff5840;
      font-size: 2.5vw;
      margin-bottom: 3vw;

      .list-item-left {
        width: 30vw;
        flex-shrink: 0;
        margin-right: 2vw;
        text-align: center;
      }

      .list-item-name {
        width: 30vw;
        text-align: center;
        // margin-right: 10vw;
      }
    }

    .list-item2 {
      display: flex;
      justify-content: center;
      color: #ff5840;
      font-size: 2.5vw;
      margin-bottom: 3vw;

      .list-item-left {
        flex-shrink: 0;

        text-align: center;
      }

      .list-item-content {
        width: 30vw;
        text-align: center;
        margin: 0 2vw;
      }

      .list-AwardStatus {
        margin-left: 2vw;
      }

      .list-item-name {
        text-align: center;
        // margin-right: 10vw;
      }
    }
  }

  .Light-award-box-tips {
    position: relative;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    text-align: center;
    color: #202020;
    font-size: 3.5vw;
  }
}

.winning-list2 {
  height: 70vw;
}

.zhezhao {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
}

.jl-box {
  position: fixed;
  z-index: 1000001;
  width: 53.025vw;
  height: 76.3vw;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .jlt-box {
    width: 53.025vw;
    height: 76.3vw;
    position: relative;
    animation: grow 1s;
    animation-fill-mode: forwards;
    /* 保留动画结束时的状态 */

    .jltc {
      width: 53.025vw;
      height: 76.3vw;
    }

    .lv_jltc {
      // width: 100px;
      height: 20.65vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-40%) translateX(-50%);
    }

    .wenben {
      width: 26.25vw;
      height: 7.525vw;
      background: linear-gradient(180deg, #ff5330 0%, #ffb127 100%);
      border-radius: 3.325vw;
      color: #fff;
      text-align: center;
      line-height: 7.525vw;
      position: absolute;
      bottom: 8.75vw;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      font-size: 2.8vw;
    }
  }

  .close {
    position: absolute;
    right: -6vw;
    top: 6vw;
    width: 6vw;
    height: 6vw;
    cursor: pointer;
  }
}

.jl-box {
  position: fixed;
  z-index: 1000001;
  width: 53.025vw;
  height: 76.3vw;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .jlt-box {
    width: 53.025vw;
    height: 76.3vw;
    position: relative;
    animation: grow 1s;
    animation-fill-mode: forwards;
    /* 保留动画结束时的状态 */

    .jltc {
      width: 53.025vw;
      height: 76.3vw;
    }

    .lv_jltc {
      // width: 100px;
      height: 20.65vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-40%) translateX(-50%);
    }

    .jltc-name {
      position: absolute;
      top: 28.07vw;
      color: #812704;
      text-align: center;
      font-size: 3.5vw;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .jltc-card {
      position: absolute;
      top: 35.08vw;
      color: #812704;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .card-item {
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #ffffff;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .card-icon {
          position: absolute;
          display: block;
          left: 50%;
          transform: translateX(-50%);
        }

        .card-item-name {
          text-align: center;
        }
      }
    }

    .jltc-card2 {
      justify-content: center;
      top: 42.1vw;

      .card-item {
        width: 15.61vw;
        height: 15.61vw;
        border-radius: 1.4vw;
        margin-right: 6.66vw;

        .card-icon {
          width: 10.17vw;
          height: auto;
          top: -2.8vw;
        }

        .card-icon1 {
          top: -4.91vw;
        }

        .card-item-name {
          margin-bottom: 4.38vw;
          color: #812704;
          font-size: 2.45vw;
          font-weight: bold;
        }
      }

      .card-item:last-child {
        margin-right: 0;
      }
    }

    .jltc-card3 {
      justify-content: center;
      top: 42.1vw;

      .card-item {
        width: 12.8vw;
        height: 12.8vw;
        border-radius: 1.4vw;
        margin-right: 2.45vw;

        .card-icon {
          width: 8.77vw;
          height: auto;
          top: -2.1vw;
        }

        .card-icon1 {
          top: -3.5vw;
        }

        .card-item-name {
          margin-bottom: 3.33vw;
          color: #812704;
          font-size: 2.1vw;
          font-weight: bold;
        }
      }

      .card-item:last-child {
        margin-right: 0;
      }
    }

    .jltc-card4 {
      justify-content: center;
      top: 35.08vw;

      .card-item {
        width: 12.08vw;
        height: 9.64vw;
        border-radius: 1.05vw;
        margin-right: 13.15vw;
        margin-top: 4.03vw;

        .card-icon {
          width: 8.77vw;
          height: auto;
          top: -2.1vw;
        }

        .card-icon1 {
          top: -3.5vw;
        }

        .card-item-name {
          margin-bottom: 2.1vw;
          color: #812704;
          font-size: 2.1vw;
          font-weight: bold;
        }
      }

      .card-item:nth-child(2n) {
        margin-right: 0;
      }
    }

    .jltc-card5 {
      justify-content: space-around;
      top: 35.08vw;
      width: calc(100% - 7vw);
      margin-left: 3.5vw;

      .card-item {
        width: 12.08vw;
        height: 9.64vw;
        border-radius: 1.05vw;
        margin-right: 2.28vw;
        margin-top: 4.03vw;

        .card-icon {
          width: 8.77vw;
          height: auto;
          top: -2.1vw;
        }

        .card-icon1 {
          top: -3.5vw;
        }

        .card-item-name {
          margin-bottom: 2.1vw;
          color: #812704;
          font-size: 2.1vw;
          font-weight: bold;
        }
      }

      .card-item:nth-child(3n) {
        margin-right: 0;
      }
    }

    .wenben {
      width: 26.25vw;
      height: 7.525vw;
      background: linear-gradient(180deg, #ff5330 0%, #ffb127 100%);
      border-radius: 3.325vw;
      color: #fff;
      text-align: center;
      line-height: 7.525vw;
      position: absolute;
      bottom: 4.73vw;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      font-size: 2.8vw;
    }
  }

  .close {
    position: absolute;
    right: -48px;
    top: 48px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.img-abs {
  position: absolute;
}

.img-abs-dis {
  display: none !important;
}

.img-abs0 {
  left: 0;
  top: 0;
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.11 / 2);
  height: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.11 / 2);
}

.img-abs1 {
  right: 0;
  top: 0;
  width: calc((100vw - 44px) / 2);
  height: calc((100vw - 44px) / 2);
}

.img-abs2 {
  left: 0;
  bottom: 0;
  width: calc((100vw - 44px) / 2);
  height: calc((100vw - 44px) / 2);
}

.img-abs3 {
  right: 0;
  bottom: 0;
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.11 / 2);
  height: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.11 / 2);
}

// 五个
.img-abs4 {
  left: 0;
  top: 0;
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.08 / 2);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.12 / 3);
}

.img-abs5 {
  right: 0;
  top: 0;
  width: calc(((100vw - 44px) / 2));
  height: calc(((100vw - 44px) / 3));
}

.img-abs6 {
  left: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 2));
  height: calc(((100vw - 44px) / 3));
}

.img-abs7 {
  right: 0;
  top: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.13 / 3);
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.085 / 2);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.24 / 3);
}

.img-abs8 {
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.12 / 3);
}

// 六个
.img-abs9 {
  left: 0;
  top: 0;
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.08 / 2);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.12 / 3);
}

.img-abs10 {
  right: 0;
  top: 0;
  width: calc(((100vw - 44px) / 2));
  height: calc(((100vw - 44px) / 3));
}

.img-abs11 {
  left: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 2));
  height: calc(((100vw - 44px) / 3));
}

.img-abs12 {
  right: 0;
  top: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.133 / 3);
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.085 / 2);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.25 / 3);
}

.img-abs13 {
  left: 0;
  bottom: 0;
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.085 / 2);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.13 / 3);
}

.img-abs14 {
  right: 0;
  bottom: 0;
  width: calc(((100vw - 44px) / 2));
  height: calc(((100vw - 44px) / 3));
}

// 七个
.img-abs15 {
  left: 0;
  top: 0;
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs16 {
  left: calc(((100vw - 44px) / 3));
  top: 0;
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs17 {
  right: 0;
  top: 0;
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs18 {
  left: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs19 {
  left: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.13 / 3);
  top: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.125 / 3);
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.25 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.12 / 3);
}

.img-abs20 {
  right: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs21 {
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

// 八个

.img-abs22 {
  left: 0;
  top: 0;
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs23 {
  left: calc(((100vw - 44px) / 3));
  top: 0;
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs24 {
  right: 0;
  top: 0;
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs25 {
  left: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs26 {
  left: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.13 / 3);
  top: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.125 / 3);
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.25 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.12 / 3);
}

.img-abs27 {
  right: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs28 {
  left: 0;
  bottom: 0;
  width: calc(((100vw - 44px) / 2) + (100vw - 44px) * 0.08 / 2);
  height: calc(((100vw - 44px) / 3));
}

.img-abs29 {
  right: 0;
  bottom: 0;
  width: calc(((100vw - 44px) / 2));
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.12 / 3);
}

// 九个

.img-abs30 {
  left: 0;
  top: 0;
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs31 {
  left: calc(((100vw - 44px) / 3));
  top: 0;
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs32 {
  right: 0;
  top: 0;
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs33 {
  left: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.115 / 3);
}

.img-abs34 {
  left: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.13 / 3);
  top: calc(((100vw - 44px) / 3) - (100vw - 44px) * 0.13 / 3);
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.25 / 3);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.25 / 3);
}

.img-abs35 {
  right: 0;
  top: calc(((100vw - 44px) / 3));
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs36 {
  left: 0;
  bottom: 0;
  width: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.08 / 2);
  height: calc(((100vw - 44px) / 3));
}

.img-abs37 {
  left: calc(((100vw - 44px) / 3));
  bottom: 0;
  width: calc(((100vw - 44px) / 3));
  height: calc(((100vw - 44px) / 3));
}

.img-abs38 {
  right: 0;
  bottom: 0;
  width: calc(((100vw - 44px) / 3)+ (100vw - 44px) * 0.08 / 2);
  height: calc(((100vw - 44px) / 3) + (100vw - 44px) * 0.12 / 3);
}
</style>
<style scoped>
.progress-box-chind >>> .el-progress-bar__outer {
  background: linear-gradient(163deg, #cd5c18 0%, #883605 100%);
}

.progress-box-chind >>> .el-progress-bar__outer {
  height: 3.75vw !important;
}

.progress-box-chind >>> .el-progress-bar__innerText {
  font-size: 3vw;
  line-height: 3.75vw !important;
  margin: 0 !important;
}
</style>
